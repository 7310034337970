import { memo, useEffect, useRef, useState } from "react";
import type { FC } from "react";

import resets from "../_resets.module.css";
import { Button_StyleFilledSizeLBrand } from "./Button_StyleFilledSizeLBrand/Button_StyleFilledSizeLBrand";
import { Footer } from "./Footer/Footer";
import { GroupIcon } from "./GroupIcon";
import classes from "./Landing.module.css";
import { PlaceholderPicture } from "./PlaceholderPicture/PlaceholderPicture";
import { SectionText_AlignmentCenter } from "./SectionText_AlignmentCenter/SectionText_AlignmentCenter";
import Navbar from "./Navbar/Navbar";
import Carousel from "./carousel/Carousel";
import WhoAmI from "./whoAmI/whoAmI";
import TrustIcons from "./trustIcons/trustIcons";
import MyProjects from "./myProjects/myProjects";

interface Props {
  className?: string;
  hide?: {
    iconJamIconsOutlineLogosPlus?: boolean;
    caption?: boolean;
    mainHeadline?: boolean;
    paragraph?: boolean;
    caption2?: boolean;
    mainHeadline2?: boolean;
    paragraph2?: boolean;
    iconJamIconsOutlineLogosHome?: boolean;
    badge?: boolean;
    iconJamIconsOutlineLogosChevro?: boolean;
    iconJamIconsOutlineLogosHome2?: boolean;
    badge2?: boolean;
    iconJamIconsOutlineLogosChevro2?: boolean;
    iconJamIconsOutlineLogosHome3?: boolean;
    badge3?: boolean;
    iconJamIconsOutlineLogosChevro3?: boolean;
    iconJamIconsOutlineLogosHome4?: boolean;
    badge4?: boolean;
    iconJamIconsOutlineLogosChevro4?: boolean;
    menuItem?: boolean;
    menuItem2?: boolean;
    badge5?: boolean;
    iconJamIconsOutlineLogosPlus2?: boolean;
    iconJamIconsOutlineLogosHome5?: boolean;
    badge6?: boolean;
    iconJamIconsOutlineLogosChevro5?: boolean;
    iconJamIconsOutlineLogosHome6?: boolean;
    badge7?: boolean;
    iconJamIconsOutlineLogosChevro6?: boolean;
    iconJamIconsOutlineLogosHome7?: boolean;
    badge8?: boolean;
    iconJamIconsOutlineLogosChevro7?: boolean;
    iconJamIconsOutlineLogosHome8?: boolean;
    badge9?: boolean;
    iconJamIconsOutlineLogosChevro8?: boolean;
    iconJamIconsOutlineLogosHome9?: boolean;
    badge10?: boolean;
    iconJamIconsOutlineLogosChevro9?: boolean;
    menuItem3?: boolean;
  };
}
/* @figmaId 143:780 */

export const Landing: FC<Props> = memo(function Landing(props = {}) {
  const [showGroup, setShowGroup] = useState(true);

  const [buttonPositionFixed, setButtonPositionFixed] = useState(true);
  const whatsappButtonRef = useRef<HTMLAnchorElement>(null);
  const ctaRef = useRef<HTMLDivElement>(null);
  const emptyDivRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      setShowGroup(window.innerWidth > 1200); // Adjust the breakpoint as needed
    };

    const handleSmoothScroll = (e: Event) => {
      const target = e.target as HTMLElement;
      if (
        target.tagName === "A" &&
        target.getAttribute("href")?.startsWith("#")
      ) {
        e.preventDefault(); // Prevent default behavior of anchor links

        // Get target section id from href attribute
        const href = target.getAttribute("href");
        if (href) {
          const targetId = href.substring(1);

          // Scroll smoothly to the target section
          document.getElementById(targetId)?.scrollIntoView({
            behavior: "smooth",
          });
        }
      }
    };
    const handleScroll = () => {
      if (ctaRef.current && whatsappButtonRef.current && emptyDivRef.current) {
        const ctaPosition = ctaRef.current.getBoundingClientRect();
        const emptyDivPosition = emptyDivRef.current.getBoundingClientRect();

        if (emptyDivPosition.top > ctaPosition.top) {
          if (buttonPositionFixed) {
            setButtonPositionFixed(false); // Set to fixed position only if not already fixed
          }
        } else if (emptyDivPosition.top < ctaPosition.top) {
          // if (!buttonPositionFixed) {
          setButtonPositionFixed(true); // Set to relative position only if not already relative
          // }
        }
      }
    };

    handleResize(); // Check initial window width
    window.addEventListener("resize", handleResize); // Listen for window resize events
    window.addEventListener("click", handleSmoothScroll); // Listen for click events
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("resize", handleResize); // Cleanup
      window.removeEventListener("click", handleSmoothScroll); // Cleanup
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); // Run once on component mount

  return (
    <div className={`${resets.storybrainResets} ${classes.root}`}>
      <Navbar />
      <div className={classes.hero}>
        <div className={classes.bG}></div>
        <div className={classes.frame33548}>
          <div className={classes.unnamed}>
            <div className={classes.textBlock}>
              התקנת עמדת טעינה
              <br /> לרכבים חשמליים
            </div>
          </div>
          <div className={classes._20}>
            <div className={classes.textBlock3} dir="rtl">
              יוסי שמש חשמלאי מוסמך מעל 20 שנים, מתמחה בהתקנת עמדות טעינה לרכבים
              חשמליים 
            </div>
          </div>
          <div className={classes.button3}>
            <Button_StyleFilledSizeLBrand
              className={classes.see}
              hide={{
                iconJamIconsOutlineLogosPlus: true,
              }}
              text={{
                buttonText: (
                  <div className={classes.buttonText}>
                    <a href="https://wa.me/972522459908?text=%D7%94%D7%99%D7%99%20%D7%99%D7%95%D7%A1%D7%99%2C%20%D7%90%D7%A0%D7%99%20%D7%9E%D7%A2%D7%95%D7%A0%D7%99%D7%99%D7%9F%20%D7%91%D7%A2%D7%9E%D7%93%D7%AA%20%D7%98%D7%A2%D7%99%D7%A0%D7%94%20%D7%9C%D7%A8%D7%9B%D7%91%20%D7%97%D7%A9%D7%9E%D7%9C%D7%99">
                      אני רוצה עמדת טעינה
                    </a>
                  </div>
                ),
              }}
            />
          </div>
        </div>
        {showGroup && (
          <div className={classes.group}>
            <GroupIcon className={classes.icon2} />
          </div>
        )}
        <div className={classes.image811}></div>
      </div>
      <div className={classes.mainDiv}>
        <div id="whoAmI">
          <WhoAmI />
        </div>
        <TrustIcons />
        <div id="ourWorks">
          <MyProjects />
        </div>
        <div
          className={classes.costumerRecommendation}
          id="customerRecommendation"
        >
          <div className={classes.sectionText4}>
            <div className={classes.top4}>
              <div className={classes.secondaryHeadline4}>לקוחות ממליצים</div>
            </div>
          </div>
          <Carousel></Carousel>
        </div>

        <div className={classes.ctaMiddle}>
          <div className={classes.see2}>
            <div className={classes.textContainer3}>
              <div className={classes.buttonText4}>
                <a href="https://wa.me/972522459908?text=%D7%94%D7%99%D7%99%20%D7%99%D7%95%D7%A1%D7%99%2C%20%D7%90%D7%A0%D7%99%20%D7%9E%D7%A2%D7%95%D7%A0%D7%99%D7%99%D7%9F%20%D7%91%D7%A2%D7%9E%D7%93%D7%AA%20%D7%98%D7%A2%D7%99%D7%A0%D7%94%20%D7%9C%D7%A8%D7%9B%D7%91%20%D7%97%D7%A9%D7%9E%D7%9C%D7%99">
                  צור קשר
                </a>
              </div>
            </div>
          </div>
          <div className={classes.unnamed4}>
            רוצים להתחדש בעמדת טעינה? דברו איתנו
          </div>
        </div>
        <div className={classes.gallery}>
          <SectionText_AlignmentCenter
            className={classes.sectionText}
            classes={{ top: classes.top }}
            hide={{
              caption: true,
              mainHeadline: true,
              paragraph: true,
            }}
            text={{
              secondaryHeadline: (
                <div className={classes.secondaryHeadline}>עבודות שלנו</div>
              ),
            }}
          />
          <div className={classes.frame1}>
            <div className={classes.contentBox}>
              <img
                className={classes.image}
                src="assets/image9211.jpg"
                alt="Image 1"
              />
            </div>
            <div className={classes.contentBox}>
              <img
                className={classes.image}
                src="assets/image01.jpeg"
                alt="Image 2"
              />
            </div>
            <div className={classes.contentBox}>
              <img
                className={classes.image}
                src="assets/image92113.jpg"
                alt="Image 3"
              />
            </div>
            <div className={classes.contentBox}>
              <img
                className={classes.image}
                src="assets/image02.jpeg"
                alt="Image 4"
              />
            </div>
            <div className={classes.contentBox}>
              <img
                className={classes.image}
                src="assets/image92115.jpg"
                alt="Image 5"
              />
            </div>
            <div className={classes.contentBox}>
              <img
                className={classes.image}
                src="assets/image03.jpeg"
                alt="Image 6"
              />
            </div>
            <div className={classes.contentBox}>
              <img
                className={classes.image}
                src="assets/image04.jpeg"
                alt="Image 7"
              />
            </div>
            <div className={classes.contentBox}>
              <img
                className={classes.image}
                src="assets/image92118.jpg"
                alt="Image 8"
              />
            </div>
            <div className={classes.contentBox}>
              <img
                className={classes.image}
                src="assets/image92119.jpg"
                alt="Image 9"
              />
            </div>
            <div className={classes.contentBox}>
              <img
                className={classes.image}
                src="assets/image921110.jpg"
                alt="Image 10"
              />
            </div>
            <div className={classes.contentBox}>
              <img
                className={classes.image}
                src="assets/image921111.jpg"
                alt="Image 11"
              />
            </div>
            <div className={classes.contentBox}>
              <img
                className={classes.image}
                src="assets/image05.jpeg"
                alt="Image 12"
              />
            </div>
            <div className={classes.contentBox}>
              <img
                className={classes.image}
                src="assets/image06.jpeg"
                alt="Image 13"
              />
            </div>
            <div className={classes.contentBox}>
              <img
                className={classes.image}
                src="assets/image07.jpeg"
                alt="Image 14"
              />
            </div>
            <div className={classes.contentBox}>
              <img
                className={classes.image}
                src="assets/image08.jpeg"
                alt="Image 15"
              />
            </div>
            <div className={classes.contentBox}>
              <img
                className={classes.image}
                src="assets/image09.jpeg"
                alt="Image 16"
              />
            </div>
            <div className={classes.contentBox}>
              <img
                className={classes.image}
                src="assets/image10.jpeg"
                alt="Image 17"
              />
            </div>
            <div className={classes.contentBox}>
              <img
                className={classes.image}
                src="assets/image11.jpeg"
                alt="Image 18"
              />
            </div>
            <div className={classes.contentBox}>
              <img
                className={classes.image}
                src="assets/image12.jpeg"
                alt="Image 19"
              />
            </div>
            <div className={classes.contentBox}>
              <img
                className={classes.image}
                src="assets/image13.jpeg"
                alt="Image 20"
              />
            </div>
          </div>
        </div>
        <div className={classes.companiesLogos}>
          <SectionText_AlignmentCenter
            className={classes.sectionText2}
            classes={{ top: classes.top2 }}
            hide={{
              caption: true,
              mainHeadline: true,
              paragraph: true,
            }}
            text={{
              secondaryHeadline: (
                <div className={classes.secondaryHeadline2}>
                  אנחנו מתקינים עמדות של כל החברות המובילות בשוק
                </div>
              ),
            }}
          />
          <div className={classes.row3}>
            <div className={`${classes.iconEach} ${classes.circleIcon}`}>
              <img src="assets/byd-logo.jpeg" alt="Icon 3 byd" />
            </div>

            <div className={`${classes.iconEach} ${classes.circleIcon}`}>
              <img src="assets/tesla-logo.jpg" alt="Icon 1 tesla" />
            </div>
            <div className={`${classes.iconEach} ${classes.circleIcon}`}>
              <img src="assets/geely-logo.jpg" alt="Icon 2 geely" />
            </div>
          </div>
          <SectionText_AlignmentCenter
            className={classes.sectionText2}
            classes={{ top: classes.top2 }}
            hide={{
              caption: true,
              mainHeadline: true,
              paragraph: true,
            }}
            text={{
              secondaryHeadline: (
                <div className={classes.secondaryHeadline2} dir="rtl">
                  ועוד...
                </div>
              ),
            }}
          />
        </div>
      </div>
      <div className={classes.cta}>
        <div className={classes.frame7}>
          <div className={classes.frame15}>
            <div className={classes.unnamed5}>
              רוצים להתקן עמדת טעינה? השאירו פרטים כאן
            </div>
          </div>
          <div ref={ctaRef}>
            <a
              ref={whatsappButtonRef}
              href="https://wa.me/972522459908?text=%D7%94%D7%99%D7%99%20%D7%99%D7%95%D7%A1%D7%99%2C%20%D7%90%D7%A0%D7%99%20%D7%9E%D7%A2%D7%95%D7%A0%D7%99%D7%99%D7%9F%20%D7%91%D7%A2%D7%9E%D7%93%D7%AA%20%D7%98%D7%A2%D7%99%D7%A0%D7%94%20%D7%9C%D7%A8%D7%9B%D7%91%20%D7%97%D7%A9%D7%9E%D7%9C%D7%99"
              target="_blank"
              className={
                buttonPositionFixed
                  ? classes.whatsappButton
                  : classes.whatsappButtonRelative
              }
            >
              <span className={classes.whatsappIcon}></span>
              <p dir="rtl">אני רוצה עמדת טעינה!</p>
            </a>
          </div>
        </div>
      </div>
      <Footer />
      <div className={classes.emptyDiv} ref={emptyDivRef}></div>
    </div>
  );
});
