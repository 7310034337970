import { memo, SVGProps } from 'react';

const VectorIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 20 17' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M20 1.907C19.2504 2.23438 18.4565 2.44904 17.644 2.544C18.4968 2.04323 19.138 1.24911 19.448 0.31C18.64 0.780328 17.7587 1.1116 16.841 1.29C16.4545 0.885206 15.9897 0.563387 15.4748 0.344178C14.9598 0.124969 14.4056 0.0129653 13.846 0.0149999C11.58 0.0149999 9.743 1.825 9.743 4.055C9.743 4.371 9.779 4.68 9.849 4.975C8.22358 4.89769 6.63212 4.48241 5.17617 3.75564C3.72022 3.02887 2.43176 2.00658 1.393 0.754C1.02883 1.3684 0.837415 2.06978 0.839 2.784C0.839697 3.45197 1.00683 4.10923 1.32529 4.69639C1.64375 5.28356 2.1035 5.78211 2.663 6.147C2.01248 6.126 1.37602 5.95233 0.805 5.64V5.69C0.805 7.648 2.22 9.281 4.095 9.653C3.74261 9.7465 3.37958 9.79389 3.015 9.794C2.75 9.794 2.493 9.769 2.242 9.719C2.51008 10.5269 3.02311 11.2313 3.70982 11.7343C4.39653 12.2374 5.22284 12.5141 6.074 12.526C4.61407 13.6505 2.82182 14.258 0.979 14.253C0.647 14.253 0.321 14.233 0 14.197C1.88125 15.3877 4.06259 16.0183 6.289 16.015C13.836 16.015 17.962 9.858 17.962 4.519L17.948 3.996C18.7529 3.42967 19.4481 2.72185 20 1.907V1.907Z'
      fill='#21272A'
    />
  </svg>
);
const Memo = memo(VectorIcon);
export { Memo as VectorIcon };
