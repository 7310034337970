import React, { useEffect, useState } from "react";
import Hamburger from "hamburger-react";
import styles from "./Navbar.module.css";

const Navbar: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 700);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 992);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={styles.header}>
      <div className={styles.yosilogoContainer}>
        <div className={styles.yosilogo1}></div>
      </div>
      {isMobile ? (
        <div className={styles.burger}>
          <Hamburger
            toggled={isOpen}
            toggle={setIsOpen}
            size={32} // Adjust the size as needed
          />
          {isOpen && (
            <div className={styles.menuContainer}>
              <ul className={styles.menu} dir='rtl'>
                <li>
                  <a href="#whoAmI" className={styles.menuItem}>
                    מי אני?
                  </a>
                </li>
                <li>
                  <a href="#customerRecommendation" className={styles.menuItem}>
                    לקוחות ממליצים
                  </a>
                </li>
                <li>
                  <a href="#ourWorks" className={styles.menuItem}>
                    עבודות שלנו
                  </a>
                </li>
                <li>
                  <a href="https://wa.me/972522459908?text=%D7%94%D7%99%D7%99%20%D7%99%D7%95%D7%A1%D7%99%2C%20%D7%90%D7%A0%D7%99%20%D7%9E%D7%A2%D7%95%D7%A0%D7%99%D7%99%D7%9F%20%D7%91%D7%A2%D7%9E%D7%93%D7%AA%20%D7%98%D7%A2%D7%99%D7%A0%D7%94%20%D7%9C%D7%A8%D7%9B%D7%91%20%D7%97%D7%A9%D7%9E%D7%9C%D7%99" className={styles.menuItem}>
                    אני רוצה עמדת טעינה
                  </a>
                </li>
              </ul>
            </div>
          )}{" "}
        </div>
      ) : (
        <div className={styles.menuContainer2}>
          <ul className={styles.menu2} dir='rtl'>
            <li>
              <a href="https://wa.me/972522459908?text=%D7%94%D7%99%D7%99%20%D7%99%D7%95%D7%A1%D7%99%2C%20%D7%90%D7%A0%D7%99%20%D7%9E%D7%A2%D7%95%D7%A0%D7%99%D7%99%D7%9F%20%D7%91%D7%A2%D7%9E%D7%93%D7%AA%20%D7%98%D7%A2%D7%99%D7%A0%D7%94%20%D7%9C%D7%A8%D7%9B%D7%91%20%D7%97%D7%A9%D7%9E%D7%9C%D7%99" className={styles.menuItem2}>
              אני רוצה עמדת טעינה
              </a>
            </li>
            <li>
              <a href="#whoAmI" className={styles.menuItem2}>
              מי אני?
              </a>
            </li>
            <li>
              <a href="#customerRecommendation" className={styles.menuItem2}>
              לקוחות ממליצים
              </a>
            </li>
            <li>
              <a href="#ourWorks" className={styles.menuItem2}>
              עבודות שלנו
              </a>
            </li>

          </ul>
        </div>
      )}
    </div>
  );
};
export default Navbar;
