import { memo } from 'react';
import type { FC } from 'react';

import resets from '../../_resets.module.css';
import { IconJamIconsOutlineLogosFacebo } from '../IconJamIconsOutlineLogosFacebo/IconJamIconsOutlineLogosFacebo';
import { IconJamIconsOutlineLogosInstag } from '../IconJamIconsOutlineLogosInstag/IconJamIconsOutlineLogosInstag';
import { IconJamIconsOutlineLogosLinked } from '../IconJamIconsOutlineLogosLinked/IconJamIconsOutlineLogosLinked';
import { IconJamIconsOutlineLogosTwitte } from '../IconJamIconsOutlineLogosTwitte/IconJamIconsOutlineLogosTwitte';
import { IconJamIconsOutlineLogosYoutub } from '../IconJamIconsOutlineLogosYoutub/IconJamIconsOutlineLogosYoutub';
import { Menu_TypeInlineOnlineStoreFals } from '../Menu_TypeInlineOnlineStoreFals/Menu_TypeInlineOnlineStoreFals';
import classes from './Footer.module.css';
import { VectorIcon } from './VectorIcon';
import { VectorIcon2 } from './VectorIcon2';
import { VectorIcon3 } from './VectorIcon3';
import { VectorIcon4 } from './VectorIcon4';
import { VectorIcon5 } from './VectorIcon5';
import { VectorIcon6 } from './VectorIcon6';
import { VectorIcon7 } from './VectorIcon7';

interface Props {
  className?: string;
  hide?: {
    iconJamIconsOutlineLogosHome?: boolean;
    badge?: boolean;
    iconJamIconsOutlineLogosChevro?: boolean;
    iconJamIconsOutlineLogosHome2?: boolean;
    badge2?: boolean;
    iconJamIconsOutlineLogosChevro2?: boolean;
    iconJamIconsOutlineLogosHome3?: boolean;
    badge3?: boolean;
    iconJamIconsOutlineLogosChevro3?: boolean;
    iconJamIconsOutlineLogosHome4?: boolean;
    badge4?: boolean;
    iconJamIconsOutlineLogosChevro4?: boolean;
    iconJamIconsOutlineLogosHome5?: boolean;
    badge5?: boolean;
    iconJamIconsOutlineLogosChevro5?: boolean;
    menuItem?: boolean;
  };
}
/* @figmaId 20:8944 */
export const Footer: FC<Props> = memo(function Footer(props = {}) {
  return (
    <div className={`${classes.footer} ${classes.root}`}>
      <div className={classes.electrify2023AllRightsReserved}>נבנה באהבה על ידי <a href='https://topapps.co.il' style={{ color: 'blue', textDecoration: 'underline' }}>Top apps</a></div>

      <div className={classes.socialIcons}>
        <IconJamIconsOutlineLogosYoutub
          swap={{
            vector: <VectorIcon className={classes.icon} />,
          }}
        />
        <IconJamIconsOutlineLogosFacebo
          swap={{
            vector: <VectorIcon2 className={classes.icon2} />,
          }}
        />
        <IconJamIconsOutlineLogosTwitte
          swap={{
            vector: <VectorIcon3 className={classes.icon3} />,
          }}
        />
        <IconJamIconsOutlineLogosInstag
          swap={{
            vector: <VectorIcon4 className={classes.icon4} />,
            vector2: <VectorIcon5 className={classes.icon5} />,
            vector3: <VectorIcon6 className={classes.icon6} />,
          }}
        />
        <IconJamIconsOutlineLogosLinked
          swap={{
            vector: <VectorIcon7 className={classes.icon7} />,
          }}
        />
      </div>
    </div>
  );
});
