import { memo, SVGProps } from 'react';

const VectorIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M14.017 0H5.947C4.37015 0.00185307 2.85844 0.629143 1.74353 1.74424C0.628623 2.85933 0.00158794 4.37115 0 5.948L0 14.018C0.00185307 15.5948 0.629143 17.1066 1.74424 18.2215C2.85933 19.3364 4.37115 19.9634 5.948 19.965H14.018C15.5948 19.9631 17.1066 19.3359 18.2215 18.2208C19.3364 17.1057 19.9634 15.5938 19.965 14.017V5.947C19.9631 4.37015 19.3359 2.85844 18.2208 1.74353C17.1057 0.628623 15.5938 0.00158794 14.017 0V0ZM17.957 14.017C17.957 14.5344 17.8551 15.0467 17.6571 15.5248C17.4591 16.0028 17.1689 16.4371 16.803 16.803C16.4371 17.1689 16.0028 17.4591 15.5248 17.6571C15.0467 17.8551 14.5344 17.957 14.017 17.957H5.947C4.90222 17.9567 3.90032 17.5415 3.16165 16.8026C2.42297 16.0638 2.008 15.0618 2.008 14.017V5.947C2.00827 4.90222 2.42349 3.90032 3.16235 3.16165C3.90122 2.42297 4.90322 2.008 5.948 2.008H14.018C15.0628 2.00827 16.0647 2.42349 16.8034 3.16235C17.542 3.90122 17.957 4.90322 17.957 5.948V14.018V14.017Z'
      fill='#21272A'
    />
  </svg>
);
const Memo = memo(VectorIcon);
export { Memo as VectorIcon };
