import { memo, SVGProps } from 'react';

const GroupIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 685 738' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g
      style={{
        mixBlendMode: 'hard-light',
      }}
      filter='url(#filter0_f_143_8863)'
    >
      <ellipse
        cx={158.264}
        cy={166.73}
        rx={158.264}
        ry={166.73}
        transform='matrix(0.907821 0.419359 -0.369079 0.929398 260.297 147.552)'
        fill='#33E9C6'
        fillOpacity={0.1}
      />
    </g>
    <g
      style={{
        mixBlendMode: 'hard-light',
      }}
      filter='url(#filter1_f_143_8863)'
    >
      <path
        d='M293.03 426.518L402.893 337.767L360.988 320.281L393.038 247.376L283.175 336.127L325.08 353.613L293.03 426.518ZM291.155 453.345C274.593 446.434 260.375 436.875 248.502 424.668C236.625 412.47 227.607 398.873 221.446 383.878C215.286 368.884 212.184 352.979 212.142 336.166C212.091 319.349 215.613 302.873 222.706 286.737C229.8 270.6 239.611 256.748 252.14 245.181C264.661 233.61 278.617 224.824 294.008 218.822C309.399 212.82 325.725 209.794 342.986 209.745C360.243 209.703 377.153 213.138 393.716 220.049C410.279 226.961 424.498 236.516 436.375 248.714C448.248 260.921 457.265 274.521 463.425 289.516C469.585 304.511 472.687 320.415 472.73 337.228C472.78 354.045 469.259 370.521 462.165 386.658C455.071 402.794 445.26 416.646 432.731 428.213C420.21 439.784 406.254 448.571 390.863 454.573C375.472 460.574 359.148 463.596 341.89 463.638C324.629 463.687 307.718 460.256 291.155 453.345Z'
        stroke='#00A8A8'
        strokeWidth={10.4945}
      />
    </g>
    <g
      style={{
        mixBlendMode: 'hard-light',
      }}
      filter='url(#filter2_f_143_8863)'
    >
      <path
        d='M293.03 426.518L402.893 337.767L360.988 320.281L393.038 247.376L283.175 336.127L325.08 353.613L293.03 426.518ZM291.155 453.345C274.593 446.434 260.375 436.875 248.502 424.668C236.625 412.47 227.607 398.873 221.446 383.878C215.286 368.884 212.184 352.979 212.142 336.166C212.091 319.349 215.613 302.873 222.706 286.737C229.8 270.6 239.611 256.748 252.14 245.181C264.661 233.61 278.617 224.824 294.008 218.822C309.399 212.82 325.725 209.794 342.986 209.745C360.243 209.703 377.153 213.138 393.716 220.049C410.279 226.961 424.498 236.516 436.375 248.714C448.248 260.921 457.265 274.521 463.425 289.516C469.585 304.511 472.687 320.415 472.73 337.228C472.78 354.045 469.259 370.521 462.165 386.658C455.071 402.794 445.26 416.646 432.731 428.213C420.21 439.784 406.254 448.571 390.863 454.573C375.472 460.574 359.148 463.596 341.89 463.638C324.629 463.687 307.718 460.256 291.155 453.345Z'
        stroke='#00A8A8'
        strokeWidth={13.7236}
      />
    </g>
    <g
      style={{
        mixBlendMode: 'hard-light',
      }}
      filter='url(#filter3_ddi_143_8863)'
    >
      <path
        d='M293.03 426.518L402.893 337.767L360.988 320.281L393.038 247.376L283.175 336.127L325.08 353.613L293.03 426.518ZM291.155 453.345C274.593 446.434 260.375 436.875 248.502 424.668C236.625 412.47 227.607 398.873 221.446 383.878C215.286 368.884 212.184 352.979 212.142 336.166C212.091 319.349 215.613 302.873 222.706 286.737C229.8 270.6 239.611 256.748 252.14 245.181C264.661 233.61 278.617 224.824 294.008 218.822C309.399 212.82 325.725 209.794 342.986 209.745C360.243 209.703 377.153 213.138 393.716 220.049C410.279 226.961 424.498 236.516 436.375 248.714C448.248 260.921 457.265 274.521 463.425 289.516C469.585 304.511 472.687 320.415 472.73 337.228C472.78 354.045 469.259 370.521 462.165 386.658C455.071 402.794 445.26 416.646 432.731 428.213C420.21 439.784 406.254 448.571 390.863 454.573C375.472 460.574 359.148 463.596 341.89 463.638C324.629 463.687 307.718 460.256 291.155 453.345Z'
        stroke='#33E9C6'
        strokeOpacity={0.2}
        strokeWidth={10.4945}
      />
    </g>
    <g filter='url(#filter4_f_143_8863)'>
      <path
        d='M293.03 426.518L402.893 337.767L360.988 320.281L393.038 247.376L283.175 336.127L325.08 353.613L293.03 426.518ZM291.155 453.345C274.593 446.434 260.375 436.875 248.502 424.668C236.625 412.47 227.607 398.873 221.446 383.878C215.286 368.884 212.184 352.979 212.142 336.166C212.091 319.349 215.613 302.873 222.706 286.737C229.8 270.6 239.611 256.748 252.14 245.181C264.661 233.61 278.617 224.824 294.008 218.822C309.399 212.82 325.725 209.794 342.986 209.745C360.243 209.703 377.153 213.138 393.716 220.049C410.279 226.961 424.498 236.516 436.375 248.714C448.248 260.921 457.265 274.521 463.425 289.516C469.585 304.511 472.687 320.415 472.73 337.228C472.78 354.045 469.259 370.521 462.165 386.658C455.071 402.794 445.26 416.646 432.731 428.213C420.21 439.784 406.254 448.571 390.863 454.573C375.472 460.574 359.148 463.596 341.89 463.638C324.629 463.687 307.718 460.256 291.155 453.345Z'
        stroke='#33E9E9'
        strokeWidth={6.45817}
      />
    </g>
    <g filter='url(#filter5_f_143_8863)'>
      <path
        d='M293.03 426.518L402.893 337.767L360.988 320.281L393.038 247.376L283.175 336.127L325.08 353.613L293.03 426.518ZM291.155 453.345C274.593 446.434 260.375 436.875 248.502 424.668C236.625 412.47 227.607 398.873 221.446 383.878C215.286 368.884 212.184 352.979 212.142 336.166C212.091 319.349 215.613 302.873 222.706 286.737C229.8 270.6 239.611 256.748 252.14 245.181C264.661 233.61 278.617 224.824 294.008 218.822C309.399 212.82 325.725 209.794 342.986 209.745C360.243 209.703 377.153 213.138 393.716 220.049C410.279 226.961 424.498 236.516 436.375 248.714C448.248 260.921 457.265 274.521 463.425 289.516C469.585 304.511 472.687 320.415 472.73 337.228C472.78 354.045 469.259 370.521 462.165 386.658C455.071 402.794 445.26 416.646 432.731 428.213C420.21 439.784 406.254 448.571 390.863 454.573C375.472 460.574 359.148 463.596 341.89 463.638C324.629 463.687 307.718 460.256 291.155 453.345Z'
        stroke='white'
        strokeWidth={3.22909}
      />
    </g>
    <defs>
      <filter
        id='filter0_f_143_8863'
        x={137.66}
        y={151.827}
        width={409.55}
        height={434.106}
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity={0} result='BackgroundImageFix' />
        <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
        <feGaussianBlur stdDeviation={24.2181} result='effect1_foregroundBlur_143_8863' />
      </filter>
      <filter
        id='filter1_f_143_8863'
        x={158.433}
        y={156.107}
        width={368.006}
        height={361.168}
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity={0} result='BackgroundImageFix' />
        <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
        <feGaussianBlur stdDeviation={24.2181} result='effect1_foregroundBlur_143_8863' />
      </filter>
      <filter
        id='filter2_f_143_8863'
        x={189.101}
        y={186.798}
        width={306.669}
        height={299.787}
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity={0} result='BackgroundImageFix' />
        <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
        <feGaussianBlur stdDeviation={8.07272} result='effect1_foregroundBlur_143_8863' />
      </filter>
      <filter
        id='filter3_ddi_143_8863'
        x={185.88}
        y={199.7}
        width={313.112}
        height={306.274}
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity={0} result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feOffset dy={10.4945} />
        <feGaussianBlur stdDeviation={4.03636} />
        <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0.3 0 0 0 0 0.275 0 0 0 0.7 0' />
        <feBlend mode='multiply' in2='BackgroundImageFix' result='effect1_dropShadow_143_8863' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feOffset dy={16.1454} />
        <feGaussianBlur stdDeviation={10.4945} />
        <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0.6 0 0 0 0 0.6 0 0 0 1 0' />
        <feBlend mode='color-dodge' in2='effect1_dropShadow_143_8863' result='effect2_dropShadow_143_8863' />
        <feBlend mode='normal' in='SourceGraphic' in2='effect2_dropShadow_143_8863' result='shape' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feOffset dx={-1.04945} dy={2.09891} />
        <feGaussianBlur stdDeviation={0.524727} />
        <feComposite in2='hardAlpha' operator='arithmetic' k2={-1} k3={1} />
        <feColorMatrix type='matrix' values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0' />
        <feBlend mode='normal' in2='shape' result='effect3_innerShadow_143_8863' />
      </filter>
      <filter
        id='filter4_f_143_8863'
        x={207.282}
        y={204.93}
        width={270.307}
        height={263.524}
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity={0} result='BackgroundImageFix' />
        <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
        <feGaussianBlur stdDeviation={0.807272} result='effect1_foregroundBlur_143_8863' />
      </filter>
      <filter
        id='filter5_f_143_8863'
        x={209.442}
        y={207.068}
        width={265.986}
        height={259.247}
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity={0} result='BackgroundImageFix' />
        <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
        <feGaussianBlur stdDeviation={0.538181} result='effect1_foregroundBlur_143_8863' />
      </filter>
    </defs>
  </svg>
);
const Memo = memo(GroupIcon);
export { Memo as GroupIcon };
