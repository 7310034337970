import { memo, SVGProps } from 'react';

const VectorIcon7 = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M19.959 11.719V19.098H15.681V12.213C15.681 10.483 15.062 9.303 13.514 9.303C12.332 9.303 11.628 10.099 11.319 10.868C11.206 11.143 11.177 11.526 11.177 11.911V19.098H6.897C6.897 19.098 6.955 7.438 6.897 6.229H11.177V8.053L11.149 8.095H11.177V8.053C11.745 7.178 12.76 5.927 15.033 5.927C17.848 5.927 19.959 7.767 19.959 11.719V11.719ZM2.421 0.026C0.958 0.026 0 0.986 0 2.249C0 3.484 0.93 4.473 2.365 4.473H2.393C3.886 4.473 4.813 3.484 4.813 2.249C4.787 0.986 3.887 0.026 2.422 0.026H2.421ZM0.254 19.098H4.532V6.229H0.254V19.098Z'
      fill='white'
    />
  </svg>
);
const Memo = memo(VectorIcon7);
export { Memo as VectorIcon7 };
