import React, { createRef, Component } from "react";
import Flicking, { FlickingError } from "@egjs/react-flicking";
import styles from "./Carousel.module.css";
import { AutoPlay } from "@egjs/flicking-plugins";

interface State {
  // Define state properties here if needed
}

class MyComponent extends Component<{}, State> {
  private flicking = createRef<Flicking>();
  private _plugins = [new AutoPlay()];

  private images = [
    "./assets/placeholderPicture6.jpg",
    "./assets/placeholderPicture7.jpg",
    "./assets/placeholderPicture8.jpg",
    "./assets/placeholderPicture9.jpg",
    "./assets/placeholderPicture10.jpg",
    "./assets/placeholderPicture11.jpg",
    "./assets/placeholderPicture12.jpg"
  ]; // Replace with your image URLs

  constructor(props: {}) {
    super(props);
    this.state = {
      // Initialize state properties here if needed
    };
  }

  render() {
    return (
      <Flicking ref={this.flicking} circular={true} plugins={this._plugins}>
        {this.images.map((image, idx) => (
          <div className={styles.panel} key={idx}>
            <img src={image} alt={`Image ${idx + 1}`} className={styles.image} />
          </div>
        ))}
      </Flicking>
    );
  }

  async moveToNextPanel() {
    try {
      await this.flicking.current?.next();
    } catch (e) {
      if (e instanceof FlickingError) {
        console.log(e instanceof FlickingError); // true
        console.log(e.code);
      }
    }
  }
}

export default MyComponent;
