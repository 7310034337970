// MyComponent.tsx
import React from 'react';
import { PlaceholderPicture } from '../PlaceholderPicture/PlaceholderPicture';
import styles from "./whoAmI.module.css";

const WhoAmI: React.FC = () => {
    return (
        <div className={styles.whatwedo}>
            <PlaceholderPicture className={styles.placeholderpictureinstance}/>
            <div className={styles.sectionText}>
                <div className={styles.secondaryheadline}>מי אני?</div>
                <p className={styles.paragraph}>
                    שמי יוסי שמש, חשמלאי מוסמך עם וותק של מעל ל-20 שנה ניסיון עשיר בתחום המטענים החשמליים לרכבים חשמליים. אני
                    מתמחה בהתקנת עמדות טעינה מתקדמות ואיכותיות.
                </p>
            </div>
        </div>
    );
};

export default WhoAmI;
