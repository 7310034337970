import { memo, SVGProps } from 'react';

const VectorIcon5 = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M5.982 0.819C4.61343 0.821116 3.30153 1.36579 2.3339 2.33361C1.36627 3.30143 0.821852 4.61343 0.82 5.982C0.821588 7.35092 1.36602 8.66333 2.3339 9.63139C3.30178 10.5995 4.61408 11.1441 5.983 11.146C7.35209 11.1444 8.66465 10.5998 9.63275 9.63175C10.6008 8.66365 11.1454 7.35109 11.147 5.982C11.1449 4.61308 10.5999 3.30088 9.63169 2.33319C8.66343 1.3655 7.35092 0.821322 5.982 0.82V0.819ZM5.982 9.138C5.14524 9.138 4.34276 8.8056 3.75108 8.21392C3.1594 7.62224 2.827 6.81976 2.827 5.983C2.827 5.14624 3.1594 4.34376 3.75108 3.75208C4.34276 3.1604 5.14524 2.828 5.982 2.828C6.81876 2.828 7.62124 3.1604 8.21292 3.75208C8.8046 4.34376 9.137 5.14624 9.137 5.983C9.137 6.81976 8.8046 7.62224 8.21292 8.21392C7.62124 8.8056 6.81876 9.138 5.982 9.138V9.138Z'
      fill='white'
    />
  </svg>
);
const Memo = memo(VectorIcon5);
export { Memo as VectorIcon5 };
