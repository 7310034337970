// MyComponent.tsx
import React from "react";
import { PlaceholderPicture } from "../PlaceholderPicture/PlaceholderPicture";
import styles from "./myProjects.module.css";

const MyProjects: React.FC = () => {
  return (
    <div className={styles.frame}>
      <div className={styles.divWrapper}>
        <div className={styles.textWrapper}>כמה מהפרויקטים שעשיתי:</div>
      </div>
      <div className={styles.div}>
        <img
          className={styles.placeholderPicture}
          alt="Placeholder picture"
          src="assets/Placeholder/picture-0.png"
        />
        <div className={styles.div2}>
          <div className={styles.textWrapper2}>עמדת טעינה לבית פרטי</div>
          <p className={styles.p}>
            קנית רכב חשמלי חדש ומעוניין להתקין עמדה בבית? אני עושה את זה!
          </p>
        </div>
      </div>
      <div className={styles.divReveresed}>
        <img
          className={styles.placeholderPicture}
          alt="Placeholder picture"
          src="assets/Placeholder/picture-1.png"
        />
        <div className={styles.div2}>
          <div className={styles.textWrapper2}>עמדת טעינה לבניין</div>
          <p className={styles.p}>
            גר בבניין ומעוניין להתקין עמדת טעינה בחניון חיצוני/ תת קרקעי? אני
            עושה את זה!
          </p>
        </div>
      </div>
      <div className={styles.div}>
        <img
          className={styles.placeholderPicture}
          alt="Placeholder picture"
          src="assets/Placeholder/picture-2.png"
        />
        <div className={styles.div2}>
          <div className={styles.textWrapper2}>עמדת טעינה לעסק</div>
          <p className={styles.p}>רוצה להטעין גם בעבודה? אני עושה את זה!</p>
        </div>
      </div>
    </div>
  );
};

export default MyProjects;
