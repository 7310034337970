// MyComponent.tsx
import React from "react";
import { PlaceholderPicture } from "../PlaceholderPicture/PlaceholderPicture";
import styles from "./trusIcons.module.css";

const TrustIcons: React.FC = () => {
  return (
    <div className={`${styles.frame} frame`}>
      <div className={`${styles.div} div`}>
        <img
          className={`${styles.img} img`}
          src="assets/fa6-solid_helmet-safety.png"
          alt="Helmet Safety"
        />
        <div className={`${styles.textWrapper} textWrapper`}>
          איכות ובטיחות
        </div>
        <p className={`${styles.p} p`}>
          עבודה באיכות גבוהה ובהתאם לתקנים המקצועיים ביותר
        </p>
      </div>
      <div className={`${styles.div} div`}>
        <img
          className={`${styles.img} img`}
          src="assets/entypo_price-tag.png"
          alt="Price Tag"
        />
        <div className={`${styles.textWrapper} textWrapper`}>מחיר הוגן</div>
        <p className={`${styles.p} p`}>
          שירות אדיב ומקצועי במחירים הוגנים ומשתלמים
        </p>
      </div>
      <div className={`${styles.div} div`}>
        <img
          className={`${styles.img} img`}
          src="assets/carbon_tool-box.png"
          alt="Tool Box"
        />
        <div className={`${styles.textWrapper} textWrapper`}>
          מקצועיות ואמינות
        </div>
        <p className={`${styles.p} p`}>
          יצירתיות בעבודה ללא פחד מתוואי ארוך/לא רגיל
        </p>
      </div>
      <div className={`${styles.div} div`}>
        <img
          className={`${styles.img} img`}
          src="assets/game-icons_three-friends.png"
          alt="Tool Box"
        />
        <div className={`${styles.textWrapper} textWrapper`}>
          ייעוץ אישי
        </div>
        <p className={`${styles.p} p`}>
          כחשמלאי מוסמך, אני נמצא לשירותך לייעוץ אישי והמלצות מותאמות
        </p>
      </div>
    </div>
  );
};

export default TrustIcons;
