import { memo } from 'react';
import type { FC, ReactNode } from 'react';

import resets from '../../_resets.module.css';
import { IconJamIconsOutlineLogosArrowR } from '../IconJamIconsOutlineLogosArrowR/IconJamIconsOutlineLogosArrowR';
import { IconJamIconsOutlineLogosPlus } from '../IconJamIconsOutlineLogosPlus/IconJamIconsOutlineLogosPlus';
import classes from './Button_StyleFilledSizeLBrand.module.css';
import { VectorIcon } from './VectorIcon';

interface Props {
  className?: string;
  classes?: {
    root?: string;
  };
  hide?: {
    iconJamIconsOutlineLogosPlus?: boolean;
  };
  text?: {
    buttonText?: ReactNode;
  };
}
/* @figmaId 1:171 */
export const Button_StyleFilledSizeLBrand: FC<Props> = memo(function Button_StyleFilledSizeLBrand(props = {}) {
  return (
    <button
      className={`${resets.storybrainResets} ${props.classes?.root || ''} ${props.className || ''} ${classes.root}`}
    >
      {!props.hide?.iconJamIconsOutlineLogosPlus && (
        <IconJamIconsOutlineLogosPlus
          className={classes.iconJamIconsOutlineLogosPlus}
          swap={{
            vector: <VectorIcon className={classes.icon} />,
          }}
        />
      )}
      <div className={classes.textContainer}>
        {props.text?.buttonText != null ? (
          props.text?.buttonText
        ) : (
          <div className={classes.buttonText}>Button Text</div>
        )}
      </div>
    </button>
  );
});
