import { memo } from 'react';
import type { FC, ReactNode } from 'react';

import resets from '../../_resets.module.css';
import classes from './SectionText_AlignmentCenter.module.css';

interface Props {
  className?: string;
  classes?: {
    top?: string;
    root?: string;
  };
  hide?: {
    caption?: boolean;
    mainHeadline?: boolean;
    paragraph?: boolean;
  };
  text?: {
    secondaryHeadline?: ReactNode;
  };
}
/* @figmaId 10:1652 */
export const SectionText_AlignmentCenter: FC<Props> = memo(function SectionText_AlignmentCenter(props = {}) {
  return (
    <div className={`${resets.storybrainResets} ${props.classes?.root || ''} ${props.className || ''} ${classes.root}`}>
      <div className={`${props.classes?.top || ''} ${classes.top}`}>
        {!props.hide?.caption && <div className={classes.caption}>Caption</div>}
        {!props.hide?.mainHeadline && <div className={classes.mainHeadline}>Purus sagittis fringilla arcu neque.</div>}
        {props.text?.secondaryHeadline != null ? (
          props.text?.secondaryHeadline
        ) : (
          <div className={classes.secondaryHeadline}>
            <div className={classes.textBlock}>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>
            <div className={classes.textBlock2}>Bibendum amet at molestie mattis.</div>
          </div>
        )}
      </div>
      {!props.hide?.paragraph && (
        <div className={classes.paragraph}>
          Rhoncus morbi et augue nec, in id ullamcorper at sit. Condimentum sit nunc in eros scelerisque sed. Commodo in
          viverra nunc, ullamcorper ut. Non, amet, aliquet scelerisque nullam sagittis, pulvinar. Fermentum scelerisque
          sit consectetur hac mi. Mollis leo eleifend ultricies purus iaculis.
        </div>
      )}
    </div>
  );
});
